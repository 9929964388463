import React from 'react'
import AppContext from '../context/AppContext';

const State = () => {
  const { state, setState } = React.useContext(AppContext);

  return (
    <div className='state'>
      <p>screen: {state.screen}</p>
      <p>clock: {state.clock}</p>
      <p>---</p>
      <p>pharmacy: {localStorage.getItem('pharmacy')}</p>
      <p>fullname: {localStorage.getItem('fullname')}</p>
      <p>---</p>
      <p>gamePreguntas: {localStorage.getItem('gamePreguntas')}</p>
      <p>gamePreguntasScore: {localStorage.getItem('gamePreguntasScore')}</p>
      <p>gamePreguntasTime: {localStorage.getItem('gamePreguntasTime')}</p>
      <p>gameDescifrar: {localStorage.getItem('gameDescifrar')}</p>
      <p>gameDescifrarScore: {localStorage.getItem('gameDescifrarScore')}</p>
      <p>gameDescifrarTime: {localStorage.getItem('gameDescifrarTime')}</p>
      <p>---</p>
      <p>gameCarrera: {localStorage.getItem('gameCarrera')}</p>
      <p>gameCarreraScore: {localStorage.getItem('gameCarreraScore')}</p>
      <p>gameCarreraLife: {localStorage.getItem('gameCarreraLife')}</p>
      <p>---</p>
      <p>gameConcentrese: {localStorage.getItem('gameConcentrese')}</p>
      <p>gameConcentreseScore: {localStorage.getItem('gameConcentreseScore')}</p>
      <p>gameConcentreseTime: {localStorage.getItem('gameConcentreseTime')}</p>
      <p>---</p>
      <p>gameBubble: {localStorage.getItem('gameBubble')}</p>
      <p>gameBubbleScore: {localStorage.getItem('gameBubbleScore')}</p>
      <p>gameBubbleShots: {localStorage.getItem('gameBubbleShots')}</p>
      <p>---</p>
    </div>
  );
}

export default State;