import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import AppContext from '../context/AppContext';
import useInitialState from '../hooks/useInitialState';
import Home from '../pages/Home';
import Welcome from '../pages/Welcome';
import Level1 from '../pages/Level1';
import Level2 from '../pages/Level2';
import Level3 from '../pages/Level3';
import Level4 from '../pages/Level4';
import Finish from '../pages/Finish';
import Ranking from '../pages/Ranking';
import NotFound from '../pages/NotFound';
import Audio from '../components/Audio';
import Preload from '../components/Preload';

const App = () => {
  const initialState = useInitialState();

  return (
    <AppContext.Provider value={initialState}>
      <Preload />
      <Audio />
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home onLoad={() => console.log('hola')} />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/level1" element={<Level1 />} />
          <Route path="/level2" element={<Level2 />} />
          <Route path="/level3" element={<Level3 />} />
          <Route path="/level4" element={<Level4 />} />
          <Route path="/finish" element={<Finish />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </AppContext.Provider>
  );
}

export default App;