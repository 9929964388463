import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import Reloj from '../components/Reloj';
import useAudio from '../hooks/useAudio';

import titulo_img from '../assets/images/level-3/texto-encuentra.png';
import excelente_img from '../assets/images/level-3/cuadro-excelente.png';
import ups_img from '../assets/images/level-3/cuadro-ups.png';
import alert_ok_omega from '../assets/images/level-3/mensaje-omega3.png';
import alert_ok_b9 from '../assets/images/level-3/mensaje-b9.png';
import alert_ok_c from '../assets/images/level-3/mensaje-c.png';
import alert_ok_b6 from '../assets/images/level-3/mensaje-b6.png';
import alert_ok_b2 from '../assets/images/level-3/mensaje-b2.png';
import alert_ok_zn from '../assets/images/level-3/mensaje-zn.png';
import alerta_referencias_img from '../assets/images/level-3/cuadro-referencias.png';
import menu_btn from '../assets/images/common/boton-volver-menu.png';
import referencias_btn from '../assets/images/common/boton-referencias.png';
import calificanos_btn from '../assets/images/common/boton-calificanos.png';

const Level3 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  const [ stateCards, setStateCards ] = React.useState({
    cards: [],
    cardsOk: [],
    score: 0,
  });

  console.log('level3');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

   const handleState = (screen, clock = 'off') => {
    setState({
      ...state,
      screen: screen,
      clock: clock,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

  const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    console.log('Save score!');

    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: localStorage.getItem('gameConcentreseScore'),
      time: localStorage.getItem('gameConcentreseTime'),
    }

    const rankingLevel3 = localStorage.getItem('rankingLevel3');

    if (!rankingLevel3) {
      console.log('no existe ranking');
      localStorage.setItem('rankingLevel3', JSON.stringify([score]));
    } else {
      console.log('ya existe ranking');
      const ranking = JSON.parse(localStorage.getItem('rankingLevel3'));
      ranking.push(score);

      localStorage.setItem('rankingLevel3', JSON.stringify(ranking));
    }

    // let formData = new FormData();
    // formData.append('name', localStorage.getItem('fullname'));
    // formData.append('pharmacy', localStorage.getItem('pharmacy'));
    // formData.append('gameConcentreseScore:', localStorage.getItem('gameConcentreseScore'));
    // formData.append('gameConcentreseTime:', localStorage.getItem('gameConcentreseTime'));
    
    // axios.post('http://127.0.0.1:8000/api/score', formData, {
    //   headers: {
    //     "Authorization": `Bearer ${ JSON.parse(localStorage.getItem('token'))} `
    //   }
    // })
    // .then((res) => {
    //   if (res.data.transaction.status) {
    //     console.log('puntaje guardado:');
    //   }
    // }).catch((err) => {
    //   console.log('error al guardar puntaje:', err);
    // })
  }

  /**
   * HANDLE SCORE
   * Agrega nuevo puntaje
   */

   const handleScore = () => {
    console.log('stateCards.score:', stateCards.score);

    if (stateCards.score === 6) {
      // setStateCards({
      //   ...stateCards,
      //   cards: [],
      //   cardsOk: [],
      //   score: 0,
      // })

      handleState('juego-ok', 'off');
      audio.stop('tickTock');
      audio.play('nice');
      audio.play('youWin');
    } else {
      handleState('juego', 'on');
      audio.play('tickTock');
    }
  }

  // CARDS

  let cards;

  if (stateCards.cards.length === 0) {
    console.log('no existe cards');
    let cardsInit = ['omega', 'b9', 'c', 'b6', 'b2', 'zn', 'omega', 'b9', 'c', 'b6', 'b2', 'zn'];
    cards = cardsInit.sort(() => Math.random() - 0.5);

    setStateCards({
      ...stateCards,
      cards: cards,
    })
  } else {
    console.log('ya existe cards');
    cards = stateCards.cards;
    console.log(cards);

    const cardsOk = stateCards.cardsOk;
    console.log('cardsOk:', cardsOk);

    if (cardsOk.length > 0) {
      setTimeout(() => {
        cardsOk.forEach(cardOk => {
          const cardOks = document.getElementsByName(cardOk);
          console.log('cardOks:', cardOks);
  
          cardOks.forEach(element => {
            element.classList.add('card-ok');
          });
        })
      }, 200);
    }
  }

  let cardSelected = {};

  const handleCard = (card, index) => {
    console.log('card: ' + card, 'index: ' + index);
    console.log('cardSelected:', Object.keys(cardSelected).length);

    const currentCard = document.getElementById(index);

    if (currentCard.classList.contains('card-ok') || currentCard.classList.contains('card-active')) {
      console.log('card ya jugo');
      return;
    }

    const lock = document.querySelector('.Level3-juego-lock');

    const cardActive = document.getElementById(index);
    cardActive.classList.add('card-active');

    if (Object.keys(cardSelected).length !== 0) {
      if (cardSelected.card === card && cardSelected.index !== index) {
        console.log('Bien!');
        lock.style.display = 'block';

        const cardsOk = stateCards.cardsOk;
        cardsOk.push(card);
        console.log(cardsOk);

        setStateCards({
          ...stateCards,
          cardsOk: cardsOk,
          score: stateCards.score + 1
        })

        console.log('stateCards:', stateCards);

        setTimeout(() => {
          const cardsActiveds = document.querySelectorAll('.card-active');
          cardsActiveds.forEach(card => {
            card.classList.remove('card-active');
            card.classList.add('card-ok');
            lock.style.display = 'none';

            handleState('juego-ok-'+card.getAttribute('name'));
            audio.play('nice');
            audio.stop('tickTock');
          });
        }, 1000);
      } else {
        console.log('Mal');
        lock.style.display = 'block';

        setTimeout(() => {
          const cardsActiveds = document.querySelectorAll('.card-active');
          cardsActiveds.forEach(card => {
            card.classList.remove('card-active');
            lock.style.display = 'none';
          });
        }, 1000);
      }
      cardSelected = {};
    } else {
      cardSelected = { card, index };
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('juego-ok');
  // }, []);

  return (
    <div className='Level3'>
      <State />
      <div className='Level3-background fadeIn' />
      <div className='Level3-logo-pharmaton' />
      <div className='Level3-franja' />
      <div className='Level3-nivel bounceIn' />
      
      {state.screen !== 'juego' &&
        <button className='button right top' onClick={() => {
          handleNavigate('welcome');
          audio.play('modalOut');
        }} >MENU</button>
      }

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gameConcentrese', 'ready') }
          { localStorage.setItem('gameConcentreseScore', 0) }
          { localStorage.setItem('gameConcentreseTime', 0) }

          <div className='Level3-presentacion zoomIn' onClick={() => {
            handleState('instrucciones');
            audio.play('button');
          }} />
          <div className='Level3-abuelitos slideRight' />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <div className='Level3-mensaje zoomIn' onClick={() => {
            handleState('juego', 'on');
            audio.play('button');
            audio.play('tickTock');
          }} />
          <div className='Level3-abuelitos' />
        </React.Fragment>
      }

      {/* JUEGO ------ */}

      {state.screen === 'juego' &&
        <React.Fragment>
          <div className='Level3-abuelitos' />
          <img className='Level3-juego-titulo-img' src={titulo_img} alt="Titulo" />
          <div className='Level3-juego-paletas zoomIn'>
            {cards.map((card, index) => (
              <div className='Level3-card' key={index} id={index} name={card} onClick={() => {
                handleCard(card, index);
                audio.play('card');
              }} />
            ))}
          </div>
          <div className='Level3-juego-lock' />
          <button className='button left bottom' onClick={() => {
            handleNavigate('welcome');
            audio.play('modalOut');
            audio.stop('tickTock');
          }} >MENU</button>

          <Reloj timeOut="60" timeOutScreen={'juego-timeout'} game="Concentrese" />
        </React.Fragment>
      }

      {/* Juego - Callbacks */}

      {state.screen === 'juego-timeout' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={ups_img} alt="Alerta" onClick={() => {
            handleState('juego', 'on');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok-omega' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={alert_ok_omega} alt="Alerta" onClick={() => {
            handleScore();
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok-b9' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={alert_ok_b9} alt="Alerta" onClick={() => {
            handleScore();
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok-c' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={alert_ok_c} alt="Alerta" onClick={() => {
            handleScore();
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok-b6' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={alert_ok_b6} alt="Alerta" onClick={() => {
            handleScore();
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok-b2' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={alert_ok_b2} alt="Alerta" onClick={() => {
            handleScore();
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok-zn' &&
        <Overlay>
          <img className='Level3-alerta-img zoomIn' src={alert_ok_zn} alt="Alerta" onClick={() => {
            handleScore();
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-ok' &&
        <Overlay>
          { localStorage.setItem('gameConcentrese', 'finish') }
          { localStorage.setItem('gameConcentreseScore', 6) }

          { console.log('enviar y limpiar datos...') }
          { console.log('gameConcentrese:', localStorage.getItem('gameConcentrese')) }
          { console.log('gameConcentreseScore:', localStorage.getItem('gameConcentreseScore')) }
          { console.log('gameConcentreseTime:', localStorage.getItem('gameConcentreseTime')) }

          { saveScore() }
          
          <img className='Level3-alerta-img zoomIn' src={excelente_img} alt="Alerta" onClick={() => {
            handleNavigate('level4');
            audio.play('button');
          }} />
          <img className='Level3-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
            handleState('referencias');
            audio.play('button');
          }} />
          <img className='Level3-calificanos-btn' src={calificanos_btn} alt="Alerta" onClick={() => {
            handleNavigate('finish');
            audio.play('button');
          }} />
          <img className='Level3-menu-btn' src={menu_btn} alt="Alerta" onClick={() => {
            handleNavigate('welcome');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'referencias' &&
        <Overlay>
          <img className='Level3-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('juego-ok');
            audio.play('modalOut');
          }} />
        </Overlay>
      }
    </div>
  );
}

export default Level3;