import React from 'react';

// screen:      Indica la pantalla actual del nivel
// clock:       Indica si el reloj esta activo

const initialState = {
  screen: '',
  clock: 'off',
  fullname: '',
  pharmacy: '',
  scoreGamePreguntas: 0,
  scoreGameDescifrar: 0,
  scoreGameCarrera: 0,
  scoreGameConcentrese: 0,
  scoreGameBoogle: 0,
  timeGamePreguntas: 0,
  timeGameDescifrar: 0,
  lifeGameCarrera: 0,
  timeGameConcentrece: 0,
  shotsGameBubble: 0,
}

const useInitialState = () => {
  const [ state, setState ] = React.useState(initialState);

  return {
    state,
    setState,
  };
}

export default useInitialState;