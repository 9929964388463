import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import useAudio from '../hooks/useAudio';

import title from '../assets/images/welcome/texto-ayudanos.png'
import subtitle from '../assets/images/welcome/texto-acompananos.png'
import empecemos from '../assets/images/welcome/boton-empecemos.png'
import titleLevels from '../assets/images/welcome/texto-selecciona.png'
import level1_img from '../assets/images/menu/foto-nivel-1.png';
import level2_img from '../assets/images/menu/foto-nivel-2.png';
import level3_img from '../assets/images/menu/foto-nivel-3.png';
import level4_img from '../assets/images/menu/foto-nivel-4.png';

const Welcome = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('welcome');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   */

   const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
    })
  }
  
  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

  const handleNavigate = (url) => {
    setState({
      ...state,
      screen: '',
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * HANDLE MUSIC
   * Inicia la musica si no esta sonando
   */

  const handleMusic = () => {
    console.log('evaluando musica');
    const music = document.querySelector('.audio-music')

    if (music.paused) {
      console.log('musica esta quieta');
      audio.restart('music');
    } else {
      console.log('musica esta sonando');
    }
  }

  return (
    <React.Fragment>
      <div className='Welcome'>
        <State />
        <div className='Welcome-background' />
        <div className='Welcome-logo-pharmaton' />
        <div className='Welcome-franja' />
        <div className='Welcome-familia-pharmaton zoomIn' />
        <img className='Welcome-title-img' src={title} alt="Titulo" />
        <img className='Welcome-subtitle-img slideUp' src={subtitle} alt="Subtitulo" />
        <img className='Welcome-empecemos-img' src={empecemos} alt="Empecemos" onClick={() => { handleState('menu'); audio.play('button'); handleMusic() }} />
        <div className='Welcome-userdata'>
          <button className='Welcome-logout-btn' onClick={() => { 
            handleNavigate(''); 
            audio.fadeOut('music');
            
            localStorage.removeItem('pharmacy');
            localStorage.removeItem('fullname');

            localStorage.removeItem('gamePreguntas');
            localStorage.removeItem('gamePreguntasScore');
            localStorage.removeItem('gamePreguntasTime');
            localStorage.removeItem('gameDescifrar');
            localStorage.removeItem('gameDescifrarScore');
            localStorage.removeItem('gameDescifrarTime');

            localStorage.removeItem('gameCarrera');
            localStorage.removeItem('gameCarreraScore');
            localStorage.removeItem('gameCarreraLife');

            localStorage.removeItem('gameConcentrese');
            localStorage.removeItem('gameConcentreseScore');
            localStorage.removeItem('gameConcentreseTime');

            localStorage.removeItem('gameBubble');
            localStorage.removeItem('gameBubbleScore');
            localStorage.removeItem('gameBubbleShots');
          }}>Cerrar sesión</button>
          <div className='Welcome-username'>{localStorage.getItem('fullname')}</div>
        </div>
        <button className='Welcome-ranking-btn' onClick={() => { 
          handleNavigate('ranking'); 
        }}>Ranking</button>

        {state.screen === 'menu' && 
          <Overlay>
            <div className='Welcome-modal'>
              <img className='Welcome-title-levels-img' src={titleLevels} alt="Titulo Niveles" />
              <div className='Welcome-levels'>
                <img className='Welcome-level-img' src={level1_img} alt="Nivel 1" onClick={() => { handleNavigate('level1'); audio.play('button') }} />
                <img className='Welcome-level-img' src={level2_img} alt="Nivel 2" onClick={() => { handleNavigate('level2'); audio.play('button') }} />
                <img className='Welcome-level-img' src={level3_img} alt="Nivel 3" onClick={() => { handleNavigate('level3'); audio.play('button') }} />
                <img className='Welcome-level-img' src={level4_img} alt="Nivel 4" onClick={() => { handleNavigate('level4'); audio.play('button') }} />
              </div>
            </div>
            <button className='button over right top' onClick={() => { handleState(''); audio.play('modalOut') }} >X</button>
          </Overlay>
        }
      </div>
    </React.Fragment>
  );
}

export default Welcome;