import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import useAudio from '../hooks/useAudio';

import instrucciones_img from '../assets/images/level-2/cuadro-instrucciones.png';
import fallaste_defensas_img from '../assets/images/level-2/cuadro-fallaste-defensas.png';
import fallaste_energia_img from '../assets/images/level-2/cuadro-fallaste-energia.png';
import fallaste_obstaculo_img from '../assets/images/level-2/cuadro-fallaste-obstaculo.png';
import alerta_referencias_img from '../assets/images/level-2/cuadro-referencias.png';
import menu_btn from '../assets/images/common/boton-volver-menu.png';
import referencias_btn from '../assets/images/common/boton-referencias.png';
import calificanos_btn from '../assets/images/common/boton-calificanos.png';

const Level2 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('level2');

  gsap.registerPlugin(Draggable);

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   */

  const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    console.log('Save score!');

    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: localStorage.getItem('gameCarreraScore'),
      life: localStorage.getItem('gameCarreraLife'),
    }

    const rankingLevel2 = localStorage.getItem('rankingLevel2');

    if (!rankingLevel2) {
      console.log('no existe ranking');
      localStorage.setItem('rankingLevel2', JSON.stringify([score]));
    } else {
      console.log('ya existe ranking');
      const ranking = JSON.parse(localStorage.getItem('rankingLevel2'));
      ranking.push(score);

      localStorage.setItem('rankingLevel2', JSON.stringify(ranking));
    }

    // let formData = new FormData();
    // formData.append('name', localStorage.getItem('fullname'));
    // formData.append('pharmacy', localStorage.getItem('pharmacy'));
    // formData.append('gameCarreraScore:', localStorage.getItem('gameCarreraScore'));
    // formData.append('gameCarreraLife:', localStorage.getItem('gameCarreraLife'));
    
    // axios.post('http://127.0.0.1:8000/api/score', formData, {
    //   headers: {
    //     "Authorization": `Bearer ${ JSON.parse(localStorage.getItem('token'))} `
    //   }
    // })
    // .then((res) => {
    //   if (res.data.transaction.status) {
    //     console.log('puntaje guardado:');
    //   }
    // }).catch((err) => {
    //   console.log('error al guardar puntaje:', err);
    // })
  }

  /**
   * HANDLE PLAY
   * Inicia el juego
   * @param {string} status   El estado play inicia el juego
   */

  const handlePlay = (status) => {
    const player = document.querySelector('.Level2-player');
    const colision = document.querySelector('.Level2-colision');
    const city = document.querySelector('.Level2-ciudad');
    const street = document.querySelector('.Level2-calle');
    const progressBar = document.querySelector('.Level2-progress-bar');
    const progressFill = document.querySelector('.Level2-progress-fill');
    const energyFill = document.querySelector('.Level2-energy-fill');
    const initBtn = document.querySelector('.Level2-comenzar-btn');
    const bacteria1 = document.querySelector('.Level2-bacteria1');
    const bacteria2 = document.querySelector('.Level2-bacteria2');
    const producto = document.querySelector('.Level2-producto');

    if (status === 'start') {
      player.classList.add('Level2-hombre-corriendo');
      city.classList.add('Level2-panning');
      street.classList.add('Level2-panning');
      progressFill.classList.add('Level2-filling');
      energyFill.classList.add('Level2-reducing');
      initBtn.style.display = 'none';
      bacteria1.classList.add('Level2-running')
      bacteria2.classList.add('Level2-running')
      producto.classList.add('Level2-running')

      let floaters = [
        bacteria1,
        bacteria2,
        producto,
      ]
      
      floaters = floaters.sort(() => Math.random() - 0.5);

      const animationMin = 1;
      const animationMax = 5;

      const topMin = 15;
      const topMax = 80;

      floaters[0].classList.add('Level2-floating1')
      floaters[1].classList.add('Level2-floating3')
      floaters[2].classList.add('Level2-floating5')

      const cleanFloatings = (element) => {
        element.classList.remove('Level2-floating1');
        element.classList.remove('Level2-floating2');
        element.classList.remove('Level2-floating3');
        element.classList.remove('Level2-floating4');
        element.classList.remove('Level2-floating5');
      }

      const setPositionFloaters = (element) => {
        const elementPosition =  element.getBoundingClientRect().left;
        
        if (elementPosition < 0) {
          cleanFloatings(element);

          let animationNumber = Math.floor(Math.random() * (animationMax - animationMin) + animationMin);
          element.classList.add('Level2-floating'+animationNumber);

          let topNumber = Math.random() * (topMax - topMin) + topMin;
          element.style.top = topNumber+'%';
        }
      }
      
      const evaluator = setInterval(() => {
        const energyFill = document.querySelector('.Level2-energy-fill');
        const lifeFill = document.querySelector('.Level2-life-fill');

        if (!energyFill) {
          console.log('no existe energyFill');
          clearInterval(evaluator);
          return;
        }
        
        if (energyFill.clientWidth < 1) {
          console.log('se acabo la energia');
          clearInterval(evaluator);

          setTimeout(() => {
            handleState('juego-error-energia');
            audio.play('youLose');
          }, 700);
          return;
        }
        
        if (lifeFill.clientWidth < 1) {
          console.log('se acabo la vida');
          clearInterval(evaluator);

          setTimeout(() => {
            handleState('juego-error-defensas');
            audio.play('youLose');
          }, 700);
          return;
        }

        if (progressFill.clientWidth >= progressBar.clientWidth - 15) {
          console.log('ganaste');
          clearInterval(evaluator);
          player.classList.remove('Level2-hombre-corriendo');
          player.classList.add('Level2-hombre-quieto');
          cleanFloatings(bacteria1)
          cleanFloatings(bacteria2)
          cleanFloatings(producto)

          setTimeout(() => {
            handleState('juego-ok');
            audio.play('nice');
            audio.play('youWin');
          }, 2000);
          return;
        }

        floaters.forEach(element => {
          setPositionFloaters(element);
        });

        if (Draggable.hitTest(player, bacteria1, 20)) {
          cleanFloatings(bacteria1);
          colision.classList.add('Level2-colision-mal');
          audio.restart('hit');

          setTimeout(() => {
            bacteria1.classList.add('Level2-floating1');
            colision.classList.remove('Level2-colision-mal');
          }, 700)

          reduceLife(20);
        }

        if (Draggable.hitTest(player, bacteria2, 20)) {
          cleanFloatings(bacteria2);
          colision.classList.add('Level2-colision-mal');
          audio.restart('hit');

          setTimeout(() => {
            bacteria2.classList.add('Level2-floating1');
            colision.classList.remove('Level2-colision-mal');
          }, 700)

          reduceLife(20);
        }
        if (Draggable.hitTest(player, producto, 20)) {
          cleanFloatings(producto);
          colision.classList.add('Level2-colision-bien');
          audio.play('recharge');

          setTimeout(() => {
            producto.classList.add('Level2-floating1');
            colision.classList.remove('Level2-colision-bien');
          }, 700)
          
          rechargeEnergy();
        }
      }, 100);
      
    } else {
      player.classList.remove('Level2-hombre-corriendo');
      city.classList.remove('Level2-panning');
      street.classList.remove('Level2-panning');
      progressFill.classList.remove('Level2-filling');
      energyFill.classList.remove('Level2-reducing');
    }
  }

  /**
   * RECHARGE ENERGY
   * Recarga toda la barra de energia
   */

  const rechargeEnergy = () => {
    const energyFill = document.querySelector('.Level2-energy-fill');
    energyFill.classList.remove('Level2-reducing');

    const storagedScore = Number(localStorage.getItem('gameCarreraScore'));
    localStorage.setItem('gameCarreraScore', storagedScore + 1);
    
    setTimeout(() => {
      energyFill.classList.add('Level2-reducing');
    }, 500);

    console.log('recargo energia');
  }

  /**
   * REDUCE LIFE
   * Reduce la barra de vida
   */

  let life = 100;

  const reduceLife = (ammout) => {
    life -= ammout;

    if (life < 0) {
      life = 0;
    }

    const lifeFill = document.querySelector('.Level2-life-fill');
    lifeFill.style.width = life+'%';

    localStorage.setItem('gameCarreraLife', life);
  }

  /**
   * HANDLE PLAYER
   * Controla al personaje
   * @param {string} action   Saltar y agacharse
   */

  const handlePlayer = (action) => {
    const player = document.querySelector('.Level2-player');

    player.classList.remove('Level2-hombre-corriendo')

    if (action === 'up') {
      player.classList.add('Level2-hombre-saltando')
      setTimeout(() => {
        player.classList.remove('Level2-hombre-saltando')
        player.classList.add('Level2-hombre-corriendo')
      }, 1000)
    } else if (action === 'down') {
      player.classList.add('Level2-hombre-agachado')
      setTimeout(() => {
        player.classList.remove('Level2-hombre-agachado')
        player.classList.add('Level2-hombre-corriendo')
      }, 800)
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('juego-ok');
  // }, []);

  return (
    <div className='Level2'>
      <State />

      {state.screen !== 'juego' &&
        <React.Fragment>
          <div className='Level2-background fadeIn' />
          <div className='Level2-logo-pharmaton' />
          <div className='Level2-franja' />
          <div className='Level2-nivel bounceIn' />
          <button className='button right top' onClick={() => {
            handleNavigate('welcome');
            audio.play('modalOut');
          }} >MENU</button>
        </React.Fragment>
      }

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gameCarrera', 'ready') }
          { localStorage.setItem('gameCarreraScore', 0) }
          { localStorage.setItem('gameCarreraLife', 100) }

          <div className='Level2-presentacion zoomIn' onClick={() => {
            handleState('instrucciones');
            audio.play('button');
          }} />
          <div className='Level2-hombre slideRight' />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <div className='Level2-mensaje zoomIn' onClick={() => {
            handleState('instrucciones-modal');
            audio.play('button');
          }} />
          <div className='Level2-hombre' />
        </React.Fragment>
      }

      {/* Instrucciones modal */}

      {state.screen === 'instrucciones-modal' &&
        <Overlay>
          <div className="Level2-modal zoomIn">
            <img className='Level2-alerta-img' src={instrucciones_img} alt="Alerta" onClick={() => {
              handleState('juego');
              audio.play('button');
            }} />
            <button className='Level2-pregunta-close-btn' onClick={() => {
              handleState('instrucciones');
              audio.play('modalOut');
            }} />
          </div>
        </Overlay>
      }

      {/* JUEGO ------ */}

      {state.screen === 'juego' &&
        <React.Fragment>
          <div className='Level2-interface'>
            <div className='Level2-header'>
              <div className="Level2-energy">
                <div className="Level2-energy-icon" />
                <div className="Level2-energy-bar">
                  <div className="Level2-energy-fill" />
                </div>
              </div>
              <div className="Level2-life">
              <div className="Level2-life-icon" />
                <div className="Level2-life-bar">
                  <div className="Level2-life-fill" />
                </div>
              </div>
            </div>
            <div className='Level2-game'>
              <div className='Level2-ciudad fadeIn' />
              <div className='Level2-calle fadeIn' />
              <div className='Level2-hombre-quieto Level2-player'>
                <div className="Level2-colision" />
              </div>
              <div className='Level2-floaters'>
                <div className='Level2-bacteria1 Level2-floating' />
                <div className='Level2-bacteria2 Level2-floating' />
                <div className='Level2-producto Level2-floating' />
              </div>
              <button className='Level2-comenzar-btn zoomOut' onClick={() => {
                handlePlay('start');
                audio.play('button');
                audio.play('goGoGo');
              }}>Comenzar</button>
            </div>
            <div className='Level2-footer'>
              <div className="Level2-progress">
                <div className="Level2-progress-icon-start" />
                <div className="Level2-progress-bar">
                  <div className="Level2-progress-fill">
                    <div className="Level2-progress-icon-avatar" />
                  </div>
                </div>
                <div className="Level2-progress-icon-end" />
              </div>
              <div className="Level2-controls">
                <div className="Level2-control-up" onClick={() => {
                  handlePlayer('up');
                  audio.play('jump');
                }} />
                <div className="Level2-control-down" onClick={() => {
                  handlePlayer('down');
                  audio.play('bending');
                }}/>
              </div>
            </div>
          </div>
          <button className='button right top' onClick={() => {
            handleState('');
            audio.play('modalOut');
          }}>SALIR</button>
        </React.Fragment>
      }

      {/* Juego - Callbacks */}

      {state.screen === 'juego-error-energia' &&
        <Overlay>
          <img className='Level1-alerta-img zoomIn' src={fallaste_energia_img} alt="Alerta" onClick={() => {
            handleState('');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-error-defensas' &&
        <Overlay>
          <img className='Level1-alerta-img zoomIn' src={fallaste_defensas_img} alt="Alerta" onClick={() => {
            handleState('');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-error-obstaculo' &&
        <Overlay>
          <img className='Level1-alerta-img zoomIn' src={fallaste_obstaculo_img} alt="Alerta" onClick={() => {
            handleState('');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {/* Ganaste */}

      {state.screen === 'juego-ok' &&
        <React.Fragment>
          { localStorage.setItem('gameCarrera', 'finish') }

          { console.log('enviar y limpiar datos...') }
          { console.log('gameCarrera:', localStorage.getItem('gameCarrera')) }
          { console.log('gameCarreraScore:', localStorage.getItem('gameCarreraScore')) }
          { console.log('gameCarreraLife:', localStorage.getItem('gameCarreraLife')) }

          { saveScore() }

          <div className='Level2-juego-ok'>
            <div className='Level2-background-2' />
            <div className='Level2-mensaje-win zoomIn' onClick={() => {
              handleNavigate('level3');
              audio.play('button');
            }} />
            <div className='Level2-hombre-nina slideRight' />
            <img className='Level2-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
              handleState('referencias');
              audio.play('button');
            }} />
            <img className='Level2-calificanos-btn' src={calificanos_btn} alt="Alerta" onClick={() => {
              handleNavigate('finish');
              audio.play('button');
            }} />
            <img className='Level2-menu-btn' src={menu_btn} alt="Alerta" onClick={() => {
              handleNavigate('welcome');
              audio.play('modalOut');
            }} />
          </div>
        </React.Fragment>
      }

      {state.screen === 'referencias' &&
        <Overlay>
          <img className='Level2-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('juego-ok');
            audio.play('modalOut');
          }}/>
        </Overlay>
      }
    </div>
  );
}

export default Level2;