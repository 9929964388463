import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import useAudio from '../hooks/useAudio';

import instrucciones_img from '../assets/images/level-4/cuadro-instrucciones.png';
import alerta_ups_img from '../assets/images/level-4/cuadro-ups.png';
import alerta_women_bien1_img from '../assets/images/level-4/cuadro-bien-mujer-1.png';
import alerta_women_bien2_img from '../assets/images/level-4/cuadro-bien-mujer-2.png';
import alerta_women_bien3_img from '../assets/images/level-4/cuadro-bien-mujer-3.png';
import alerta_women_bien4_img from '../assets/images/level-4/cuadro-bien-mujer-4.png';
import alerta_women_bien5_img from '../assets/images/level-4/cuadro-bien-mujer-5.png';
import alerta_women_bien6_img from '../assets/images/level-4/cuadro-bien-mujer-6.png';
import alerta_men_bien1_img from '../assets/images/level-4/cuadro-bien-hombre-1.png';
import alerta_men_bien2_img from '../assets/images/level-4/cuadro-bien-hombre-2.png';
import alerta_men_bien3_img from '../assets/images/level-4/cuadro-bien-hombre-3.png';
import alerta_men_bien4_img from '../assets/images/level-4/cuadro-bien-hombre-4.png';
import alerta_men_bien5_img from '../assets/images/level-4/cuadro-bien-hombre-5.png';
import alerta_men_bien6_img from '../assets/images/level-4/cuadro-bien-hombre-6.png';
import alerta_lograste_img from '../assets/images/level-4/cuadro-lograste.png';
import alerta_referencias_img from '../assets/images/level-4/cuadro-referencias.png';
import menu_btn from '../assets/images/common/boton-volver-menu.png';
import referencias_btn from '../assets/images/common/boton-referencias.png';

const Level4 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('level4');

  gsap.registerPlugin(Draggable);

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   */

   const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * HANDLE POPUP
   * Abre o cierra un popup
   * @param {string} popupName   Nombre del popup
   */

  const handlePopup= (popupName) => {
    if (popupName) {
      const popup = document.querySelector('.'+popupName);
      popup.classList.add('show');
    } else {
      if (popupsMen.length === 0 && popupsWomen.length === 0) {
        handleState('juego-win');
        audio.play('nice');
        audio.play('youWin');
        audio.stop('tickTock');
      }

      const popups = document.querySelectorAll('.Level4-popup');
      popups.forEach(popup => {
        popup.classList.remove('show');
      });
      
    }
  }

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    console.log('Save score!');

    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: localStorage.getItem('gameBubbleScore'),
      shots: localStorage.getItem('gameBubbleShots'),
    }

    const rankingLevel4 = localStorage.getItem('rankingLevel4');

    if (!rankingLevel4) {
      console.log('no existe ranking');
      localStorage.setItem('rankingLevel4', JSON.stringify([score]));
    } else {
      console.log('ya existe ranking');
      const ranking = JSON.parse(localStorage.getItem('rankingLevel4'));
      ranking.push(score);

      localStorage.setItem('rankingLevel4', JSON.stringify(ranking));
    }

    // let formData = new FormData();
    // formData.append('name', localStorage.getItem('fullname'));
    // formData.append('pharmacy', localStorage.getItem('pharmacy'));
    // formData.append('gameBubbleScore:', localStorage.getItem('gameBubbleScore'));
    // formData.append('gameBubbleShots:', localStorage.getItem('gameBubbleShots'));
    
    // axios.post('http://127.0.0.1:8000/api/score', formData, {
    //   headers: {
    //     "Authorization": `Bearer ${ JSON.parse(localStorage.getItem('token'))} `
    //   }
    // })
    // .then((res) => {
    //   if (res.data.transaction.status) {
    //     console.log('puntaje guardado:');
    //   }
    // }).catch((err) => {
    //   console.log('error al guardar puntaje:', err);
    // })
  }

  // INICIO

  if (state.screen === 'juego') {
    setTimeout(() => {
      const base = document.querySelector('.Level4-base');
      const weapon = document.querySelector('.Level4-weapon');

      // Rotacion del arma 
      Draggable.create(weapon, {
        type: "rotation", 
        inertia: true,
        bounds: { minRotation:-60, maxRotation:60 },
        onDrag: () => handleRotation(Draggable)
      });

      // Crear bolas estaticas aleatoriamente
      let colors = ['red', 'green', 'blue', 'fucsia'];

      let rowsCount = 8;
      let columnsCount;

      for (let i = 1; i <= rowsCount; i++) {
        if (i%2 === 0) {
          columnsCount = 26
        } else {
          columnsCount = 25
        }

        for (let index = 1; index <= columnsCount; index++) {
          const ball = document.createElement('div');
          ball.classList.add('Level4-ball');
          ball.classList.add('Level4-ball-inactive');
          ball.classList.add('row'+i);
          ball.classList.add('col'+index);
          ball.style.gridRow = i;
          ball.style.gridColumn = index;
          ball.style.top = 0;
          ball.style.left = 0;
          // ball.innerHTML = i+'/'+index;
          
          colors = colors.sort(() => Math.random() - 0.5);
          ball.classList.add('Level4-ball-color-'+colors[0]);
          ball.setAttribute('color', colors[0]);
          ball.setAttribute('live', 'true');
  
          const random = Math.random()
  
          if (random > 0.3) {
            base.prepend(ball);
          }
        }
      }
    }, 1000);
  }

  // POPUPS CALLBACKS 

  let popupsMen = [
    'juego-men-ok1',
    'juego-men-ok2',
    'juego-men-ok3',
    'juego-men-ok4',
    'juego-men-ok5',
    'juego-men-ok6',
  ]

  let popupsWomen = [
    'juego-women-ok1',
    'juego-women-ok2',
    'juego-women-ok3',
    'juego-women-ok4',
    'juego-women-ok5',
    'juego-women-ok6',
  ]

  // ROTACION

  const handleRotation = (Draggable) => {
    const weapon = document.querySelector('.Level4-weapon');
    const cursor = document.querySelector('.Level4-cursor');

    const angle = Draggable.get(weapon).rotation;
    cursor.style.left = angle + (angle * 0.3) + 50 + '%';
  }

  // CREAR BOLA

  const createBall = () => {
    setTimeout(() => {

      // Si no existe una bola activa, la crea
      const ballActiveExist = document.querySelector('.Level4-ball-active');
  
      if (!ballActiveExist) {
        console.log('creando bola');
    
        let colors = ['red', 'green', 'blue', 'fucsia', 'blue', 'fucsia'];
        const random = Math.floor(Math.random() * 6);
    
        const base = document.querySelector('.Level4-base');
        const ball = document.createElement('div');
        ball.classList.add('Level4-ball');
        ball.classList.add('Level4-ball-active');
        ball.classList.add('Level4-ball-ready');
        ball.classList.add('Level4-ball-color-'+colors[random]);
        ball.setAttribute('color', colors[random]);
        ball.setAttribute('live', 'true');
        base.prepend(ball);

        audio.play('bubble');
      }

    }, 200);
  }

  // DISPARO

  if (state.screen === 'juego') {
    setTimeout(() => {

      // Muestra la cantidad de disparos disponibles
      const shots = document.querySelector('.Level4-shots-number');
      shots.innerHTML = localStorage.getItem('gameBubbleShots');

      // Crea una nueva bola con color aleatorio
      createBall();

    }, 500);
  }
  
  // Controla el disparo
  const handleShot = () => {

    // Finaliza el juego si se acaban los disparos
    if (Number(localStorage.getItem('gameBubbleShots')) === 0) {
      handleState('juego-ups');
      audio.play('wrong');
      audio.play('youLose');
      return;
    }

    // Si la bola esta lista, procede a dispararla
    const ballReadyExist = document.querySelector('.Level4-ball-ready');
  
    if (ballReadyExist) {

      // Elimina la clase ready para evitar doble disparo
      ballReadyExist.classList.remove('Level4-ball-ready');

      // Reduce los disparos disponibles
      localStorage.setItem('gameBubbleShots', Number(localStorage.getItem('gameBubbleShots')) - 1);
      
      // Muestra los disparos disponibles actalizados
      const shots = document.querySelector('.Level4-shots-number');
      shots.innerHTML = localStorage.getItem('gameBubbleShots');
      
      setTimeout(() => {

        // Dispara la bola hasta la posicion del cursor
        const cursor = document.querySelector('.Level4-cursor');
        const ballActive = document.querySelector('.Level4-ball-active');

        ballActive.style.left = cursor.style.left;
        ballActive.style.top = cursor.style.top;

        // Evalua todas las bolas en busca de cualquier colision 
        const inactiveBalls = document.querySelectorAll('.Level4-ball-inactive');
        const base = document.querySelector('.Level4-base');
        const baseLeft = base.getBoundingClientRect().left;
        const baseRight = base.getBoundingClientRect().right;

        const evaluator = setInterval(() => {
          inactiveBalls.forEach(inactiveBall => {

            // Encuentra una colision
            if (Draggable.hitTest(ballActive, inactiveBall, 0)) {
              
              // Detiene el evaluador e inactiva la bola
              console.log('colision');
              clearTimeout(timeoutBall);
              clearInterval(evaluator);
              ballActive.classList.remove('Level4-ball-active');
              ballActive.classList.add('Level4-ball-inactive');
              stopBall();
              audio.play('recharge');
              
            }

            // La bola sale del recuadro
            if (ballActive.getBoundingClientRect().left < baseLeft || ballActive.getBoundingClientRect().left > baseRight) {

              // Detiene el evaluador e inactiva la bola
              console.log('salio');
              clearTimeout(timeoutBall);
              clearInterval(evaluator);
              ballActive.classList.remove('Level4-ball-active');
              ballActive.classList.add('Level4-ball-inactive');
              stopBall();

            }
            
          })
        }, 16);

        // Detiene el evaluador e inactiva la bola luego de 2 segundos
        const timeoutBall = setTimeout(() => {
          clearInterval(evaluator);
          ballActive.classList.remove('Level4-ball-active');
          ballActive.classList.add('Level4-ball-inactive');
          stopBall();
        }, 2000);

        // Acciones al detener la bola 
        const stopBall = () => {

          // Encaja la bola en la cuadricula
          const base = document.querySelector('.Level4-base');

          const left = ballActive.getBoundingClientRect().left - base.getBoundingClientRect().left;
          const top = ballActive.getBoundingClientRect().top - base.getBoundingClientRect().top;
          
          const unit_x = base.clientWidth / 26
          const unit_y = base.clientHeight / 18

          var col = Math.round(left / unit_x);
          var row = Math.round(top / unit_y);

          if (col > 25) {
            col = 25;
          }

          // ballActive.innerHTML = `${row + 1}/${col + 1}`;

          ballActive.style.transition = 'none';
          ballActive.style.left = 0;
          ballActive.style.top = 0;

          ballActive.style.position = 'relative'
          ballActive.style.gridRow = row + 1;
          ballActive.style.gridColumn = col + 1;

          // Revisa si ya hay otra bola en esa posicion 
          if (document.querySelector(`.row${row}.col${col}`)) {
            console.log('ya existe una bola en esa posicion');
          }

          // Agrega las clases row y col a la bola
          ballActive.classList.add(`row${row + 1}`);
          ballActive.classList.add(`col${col + 1}`);

          // Array vacio para almacenar bolas contiguas
          let ballsParents = [];
          let colisionColor;
          
          // Reaccion de las otras bolas al ser tocadas
          const reactionBalls = (ball, principal = true) => {
            console.log('--- reaccionando');

            colisionColor = ball.getAttribute('color');

            // Posiciona la bola en la grilla
            const ballRowStyle = ball.style.gridRow;
            const ballColumnStyle = ball.style.gridColumn;

            const ballRow = ballRowStyle.replace(' / auto', '');
            const ballColumn = ballColumnStyle.replace(' / auto', '');

            console.log('ballRow:', ballRow);
            console.log('ballColumn:', ballColumn);

            // Busca bolas contiguas a su alrededor
            const contiguosTopLeft =  `.row${Number(ballRow)-1}.col${Number(ballColumn)-1}`;
            const contiguosTop =  `.row${Number(ballRow)-1}.col${Number(ballColumn)}`;
            const contiguosTopRight =  `.row${Number(ballRow)-1}.col${Number(ballColumn)+1}`;

            const contiguosLeft =  `.row${Number(ballRow)}.col${Number(ballColumn)-1}`;
            const contiguosRight =  `.row${Number(ballRow)}.col${Number(ballColumn)+1}`;
            
            const contiguosBottomLeft =  `.row${Number(ballRow)+1}.col${Number(ballColumn)-1}`;
            const contiguosBottom =  `.row${Number(ballRow)+1}.col${Number(ballColumn)}`;
            const contiguosBottomRight =  `.row${Number(ballRow)+1}.col${Number(ballColumn)+1}`;
            
            console.log(contiguosTopLeft, contiguosTop, contiguosTopRight);
            console.log(contiguosLeft, contiguosRight);
            console.log(contiguosBottomLeft, contiguosBottom, contiguosBottomRight);

            const ballContiguousTopLeft = document.querySelector(contiguosTopLeft);
            const ballContiguousTop = document.querySelector(contiguosTop);
            const ballContiguousTopRight = document.querySelector(contiguosTopRight);

            const ballContiguousLeft = document.querySelector(contiguosLeft);
            const ballContiguousRight = document.querySelector(contiguosRight);

            const ballContiguousBottomLeft = document.querySelector(contiguosBottomLeft);
            const ballContiguousBottom = document.querySelector(contiguosBottom);
            const ballContiguousBottomRight = document.querySelector(contiguosBottomRight);

            // Determina si al colisionar la fila es par o impar 
            let row_colision;

            if (ballRow%2 !== 0) {
              console.log('par');
              row_colision = 'par'
            } else {
              console.log('inpar');
              row_colision = 'inpar'
            }

            // TopLeft - Colision con bolas del mismo color
            if (ballContiguousTopLeft && row_colision === 'inpar') {
              if (ball.getAttribute('color') === ballContiguousTopLeft.getAttribute('color') && ballContiguousTopLeft.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousTopLeft.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');

                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousTopLeft, false);
              }
            }

            // Top - Colision con bolas del mismo color
            if (ballContiguousTop) {
              if (ball.getAttribute('color') === ballContiguousTop.getAttribute('color') && ballContiguousTop.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousTop.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousTop, false);
              }
            }

            // TopRight - Colision con bolas del mismo color
            if (ballContiguousTopRight && row_colision === 'par') {
              if (ball.getAttribute('color') === ballContiguousTopRight.getAttribute('color') && ballContiguousTopRight.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousTopRight.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousTopRight, false);
              }
            }
            
            // Left - Colision con bolas del mismo color
            if (ballContiguousLeft) {
              if (ball.getAttribute('color') === ballContiguousLeft.getAttribute('color') && ballContiguousLeft.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousLeft.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousLeft, false);
              }
            }

            // Right - Colision con bolas del mismo color
            if (ballContiguousRight) {
              if (ball.getAttribute('color') === ballContiguousRight.getAttribute('color') && ballContiguousRight.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousRight.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousRight, false);
              }
            }

            // BottomLeft - Colision con bolas del mismo color
            if (ballContiguousBottomLeft && row_colision === 'inpar') {
              if (ball.getAttribute('color') === ballContiguousBottomLeft.getAttribute('color') && ballContiguousBottomLeft.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousBottomLeft.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousBottomLeft, false);
              }
            }

            // Bottom - Colision con bolas del mismo color
            if (ballContiguousBottom) {
              if (ball.getAttribute('color') === ballContiguousBottom.getAttribute('color') && ballContiguousBottom.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousBottom.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousBottom, false);
              }
            }

            // BottomRight - Colision con bolas del mismo color
            if (ballContiguousBottomRight && row_colision === 'par') {
              if (ball.getAttribute('color') === ballContiguousBottomRight.getAttribute('color') && ballContiguousBottomRight.getAttribute('live') === 'true') {
                console.log('::buscador: ' + ball.classList);
                console.log(':::encontro: ' + ballContiguousBottomRight.classList);
                ball.style.opacity = 0.2;
                ball.setAttribute('live', 'false');
                
                if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                  ballsParents.push(`.row${ballRow}.col${ballColumn}`);
                }

                // Busca bolas contiguas en la bola colisionada
                reactionBalls(ballContiguousBottomRight, false);
              }
            }

            // Si no hay colision pero no es la bola principal 
            if (principal === false) {
              ball.style.opacity = 0.2;
              
              if (!ballsParents.includes(`.row${ballRow}.col${ballColumn}`)) {
                ballsParents.push(`.row${ballRow}.col${ballColumn}`);
              }
            }
          }

          reactionBalls(ballActive);

          // Acciones al final de cada disparo
          setTimeout(() => {
            const minBalls = 3;

            console.log('ballsParents:', ballsParents);
            console.log('ballsParents.length:', ballsParents.length);

            // Elimina bolas si impacta 3 o mas bolas
            if (ballsParents.length >= minBalls) {
              console.log('bien elimina bolas');

              ballsParents.forEach(element => {

                // Elimina las bolas impactadas
                const ballImpacted = document.querySelector(element);
                ballImpacted.remove();
                
                // Suma puntos si impacta bolas azules o fucsias
                if (ballImpacted.getAttribute('color') === 'blue' || ballImpacted.getAttribute('color') === 'fucsia') {
                  console.log('suma puntos');
                  localStorage.setItem('gameBubbleScore', Number(localStorage.getItem('gameBubbleScore')) + 1)
                  const scoreNumber = document.querySelector('.Level4-score-number');
                  scoreNumber.innerHTML = localStorage.getItem('gameBubbleScore');     
                  audio.play('nice');           
                } else {
                  console.log('no suma puntos');
                }

              });
            } else {
              console.log('bien pero no elimina bolas');

              ballsParents.forEach(element => {

                // Regresa a su estado normal las bolas impactadas
                const ballImpacted = document.querySelector(element);
                ballImpacted.style.opacity = 1;
                ballImpacted.setAttribute('live', 'true');

              });
            }

            // Abre el popup callback
            if (ballsParents.length >= minBalls) {
              console.log('ballsParents:', ballsParents);

              if (colisionColor === 'blue') {
                handlePopup(popupsMen[0]);
                popupsMen.shift();
              }
  
              if (colisionColor === 'fucsia') {
                handlePopup(popupsWomen[0]);
                popupsWomen.shift();
              }
            }

            console.log('popupsMen:', popupsMen);
            console.log('popupsWomen:', popupsWomen);

            // Crea una nueva bola con color aleatorio
            createBall();
          }, 1000);

        }
      }, 200);

    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('juego-win');
  // }, []);

  return (
    <div className='Level4'>
      <State />
      <div className='Level4-background fadeIn' />
      <div className='Level4-logo-pharmaton' />
      <div className='Level4-franja' />
      <div className='Level4-nivel bounceIn' />
      <button className='button right top' onClick={() => {
        handleNavigate('welcome');
        audio.play('button');
      }} >MENU</button>

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gameBubble', 'ready') }
          { localStorage.setItem('gameBubbleScore', 0) }
          { localStorage.setItem('gameBubbleShots', 50) }

          <div className='Level4-presentacion zoomIn' onClick={() => {
            handleState('instrucciones');
            audio.play('button');
          }} />
          <div className='Level4-personas slideRight' />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <div className='Level4-mensaje zoomIn' onClick={() => {
            handleState('instrucciones-modal');
            audio.play('button');
          }} />
          <div className='Level4-personas' />
        </React.Fragment>
      }

      {/* Instrucciones modal */}

      {state.screen === 'instrucciones-modal' &&
        <Overlay>
          <div className="Level4-modal zoomIn">
            <img className='Level4-alerta-img' src={instrucciones_img} alt="Alerta" onClick={() => {
              handleState('juego');
              audio.play('button');
            }} />
            <button className='Level4-pregunta-close-btn' onClick={() => {
              handleState('instrucciones');
              audio.play('modalOut');
            }} />
          </div>
        </Overlay>
      }

      {/* JUEGO ------ */}

      {state.screen === 'juego' &&
        <Overlay>
          <div className="Level4-score slideUp">
            <p className='Level4-score-title'>Puntaje</p>
            <p className='Level4-score-number'>0</p>
          </div>
          <div className="Level4-marco" />
          <div className="Level4-base">
            <div className="Level4-weapon" />
            <div className="Level4-trigger" onClick={() => {
              handleShot();
              audio.play('shot');
            }} onTouchEnd={() => {
              handleShot();
              audio.play('shot');
            }} />
            <div className="Level4-cursor" style={{top: '0vh', left: 'calc(50% - 25px)'}}>+</div>
          </div>
          <div className="Level4-products slideLeft" />
          <div className="Level4-shots slideRight">
            <p className="Level4-shots-title">TIROS</p>
            <p className="Level4-shots-number">0</p>
          </div>
          <button className='button right top over' onClick={() => {
            handleState('');
            audio.play('modalOut');
          }}>SALIR</button>
        </Overlay>
      }

      {/* Juego - Callbacks */}

      <div className='Level4-popup juego-women-ok1'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_women_bien1_img} alt="Alerta" onClick={() => {
              handlePopup('');
              audio.play('modalOut');
            }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-women-ok2'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_women_bien2_img} alt="Alerta" onClick={() => {
              handlePopup('');
              audio.play('modalOut');
            }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-women-ok3'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_women_bien3_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-women-ok4'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_women_bien4_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-women-ok5'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_women_bien5_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-women-ok6'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_women_bien6_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-men-ok1'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_men_bien1_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-men-ok2'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_men_bien2_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-men-ok3'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_men_bien3_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-men-ok4'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_men_bien4_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-men-ok5'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_men_bien5_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      <div className='Level4-popup juego-men-ok6'>
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_men_bien6_img} alt="Alerta" onClick={() => {
            handlePopup('');
            audio.play('modalOut');
          }} />
        </Overlay>
      </div>

      {state.screen === 'juego-ups' &&
        <Overlay>
          <img className='Level4-alerta-img zoomIn' src={alerta_ups_img} alt="Alerta" onClick={() => {
            handleState('');
            audio.play('modalOut');
          }} />
          <img className='Level4-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
            handleState('referencias-ups');
            audio.play('button');
          }} />
        </Overlay>
      }

      {state.screen === 'juego-win' &&
        <Overlay>
          { localStorage.setItem('gameBubble', 'finish') }

          { console.log('enviar y limpiar datos...') }
          { console.log('gameBubble:', localStorage.getItem('gameBubble')) }
          { console.log('gameBubbleScore:', localStorage.getItem('gameBubbleScore')) }
          { console.log('gameBubbleShots:', localStorage.getItem('gameBubbleShots')) }

          { saveScore() }

          <img className='Level4-alerta-img zoomIn' src={alerta_lograste_img} alt="Alerta" onClick={() => {
            handleNavigate('finish');
            audio.play('button');
          }} />
          <img className='Level4-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
            handleState('referencias-win');
            audio.play('button');
          }} />
          <img className='Level4-menu-btn' src={menu_btn} alt="Alerta" onClick={() => {
            handleNavigate('welcome');
            audio.play('modalOut');
          }} />
        </Overlay>
      }
      
      {state.screen === 'referencias-ups' &&
        <Overlay>
          <img className='Level4-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('juego-ups');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'referencias-win' &&
        <Overlay>
          <img className='Level4-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('juego-win');
            audio.play('modalOut');
          }} />
        </Overlay>
      }
    </div>
  );
}

export default Level4;