import React from 'react'

const NotFound = () => {
  return (
    <div className='NotFound'>
      <div className='NotFound-background' />
      <div className='NotFound-logo-pharmaton' />
      <div className='NotFound-franja' />
      <div className='NotFound-texto'>Página no encontrada</div>
    </div>
  );
}

export default NotFound;