import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import axios from "axios";
import State from '../components/State';
import useAudio from '../hooks/useAudio';

import title from '../assets/images/finish/texto-maximo.png'
import subtitle from '../assets/images/finish/texto-gracias.png'
import buena from '../assets/images/finish/boton-buena.png'
import regular from '../assets/images/finish/boton-regular.png'
import mala from '../assets/images/finish/boton-mala.png'
import apreciamos from '../assets/images/finish/texto-apreciamos.png'
import adios from '../assets/images/finish/adios.png'
import logoPharmaton from '../assets/images/common/logo-pharmaton.png';
import legal from '../assets/images/finish/texto-legal.png';

const Finish = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('finish');

  /**
   * HANDLE STATE
   * Establece el nivel en 1
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

   const handleState = (screen) => {
    setState({
      ...state,
      level: '',
      screen: screen,
    })
  }
  
  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

  const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * HANDLE OPINION
   * Guarda los datos en el servidor
   * @param {number} opinion  Calificacion
   */
  const handleOpinion = (opinion) => {
    let formData = new FormData();
    formData.append('opinion', opinion)
    axios.post('http://127.0.0.1:8000/api/opinion/create-opinion', formData, {
      headers:{
        "Authorization": `Bearer ${ JSON.parse(localStorage.getItem('token'))}`
      }
    })
    .then((res) => {
      handleState('adios');
    })
    .catch((err) => {
      console.log('error al guardar la opinion:', err);
      handleState('adios');
    })
  }

  return (
    <div className='Finish'>
      <State />
      <div className='Finish-background' />
      <div className='Finish-logo-pharmaton' />
      <div className='Finish-franja' />
      
      {!state.screen &&
        <React.Fragment>
          <div className='Finish-familia-pharmaton' />
          <img className='Finish-title-img' src={title} alt="Titulo" />
          <img className='Finish-subtitle-img' src={subtitle} alt="Subtitulo" />
          <div className="Finish-evaluation">
            <img className='Finish-buena-img' src={buena} alt="Buena" onClick={() => {
              handleOpinion(1);
              audio.play('button');
            }} />
            <img className='Finish-regular-img' src={regular} alt="Regular" onClick={() => {
              handleOpinion(2);
              audio.play('button');
            }} />
            <img className='Finish-mala-img' src={mala} alt="Mala" onClick={() => {
              handleOpinion(3);
              audio.play('button');
            }} />
          </div>
        </React.Fragment>
      }

      {state.screen === 'adios' && 
        <React.Fragment>
          <div className="Finish-adios">
            <img className='Finish-apreciamos-img' src={apreciamos} alt="Apreciamos" />
            <img className='Finish-adios-img' src={adios} alt="Adios" onClick={() => {
              handleState('cierre');
              audio.play('button');
            }} />
          </div>
        </React.Fragment>
      }

      {state.screen === 'cierre' && 
        <React.Fragment>
          <div className='Finish-cierre' onClick={() => {
            navigate('/', { replace: true });
            audio.play('button');

            localStorage.removeItem('pharmacy');
            localStorage.removeItem('fullname');

            localStorage.removeItem('gamePreguntas');
            localStorage.removeItem('gamePreguntasScore');
            localStorage.removeItem('gamePreguntasTime');
            localStorage.removeItem('gameDescifrar');
            localStorage.removeItem('gameDescifrarScore');
            localStorage.removeItem('gameDescifrarTime');

            localStorage.removeItem('gameCarrera');
            localStorage.removeItem('gameCarreraScore');
            localStorage.removeItem('gameCarreraLife');

            localStorage.removeItem('gameConcentrese');
            localStorage.removeItem('gameConcentreseScore');
            localStorage.removeItem('gameConcentreseTime');

            localStorage.removeItem('gameBubble');
            localStorage.removeItem('gameBubbleScore');
            localStorage.removeItem('gameBubbleShots');
          }}>
            <img className='Finish-logo-pharmaton-img' src={logoPharmaton} alt="Logo Pharmaton" />
            <img className='Finish-legal-img' src={legal} alt="Legal" />
          </div>
        </React.Fragment>
      }
    </div>
  );
}

export default Finish;