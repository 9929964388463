import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import axios from "axios";
import { useForm } from "react-hook-form";
import State from '../components/State';
import Overlay from '../components/Overlay';
import useAudio from '../hooks/useAudio';

import registrarse from '../assets/images/home/boton-registrarse.png';
import registro from '../assets/images/home/cuadro-registro.png';

const Home = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const audio = useAudio();

  console.log('home');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   */

   const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
    })
  }

  /**
   * ON SUBMIT
   * Hace login en el servidor
   * Guarda los datos con localstorage
   * Cierra el modal
   * Abre la ruta welcome
   * @param {any} data  Datos del formulario
   */

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append('name', data.fullname);
    formData.append('email', data.fullname.replace(' ', '') + '@hotmail.com'); 
    formData.append('password', 'password');
    
    axios.post('http://127.0.0.1:8000/api/auth/login', formData)
    .then((res) => {
      if (res.data.transaction.status) {
        localStorage.setItem('token', JSON.stringify(res.data.data.token))
        localStorage.setItem('user', JSON.stringify(res.data.data.user))
        localStorage.setItem('pharmacy', data.pharmacy);
        localStorage.setItem('fullname', data.fullname);

        setState({
          ...state,
          screen: '',
        })
        
        audio.play('button')
        navigate('/welcome', { replace: true });
      }
    }).catch((err) => {
      const error = document.querySelector('.Home-registro-error');
      error.innerHTML = 'No fue posible registrar los datos. (' + err + ').';

      localStorage.setItem('pharmacy', data.pharmacy);
      localStorage.setItem('fullname', data.fullname);

      localStorage.setItem('gamePreguntas', '');
      localStorage.setItem('gamePreguntasScore', '');
      localStorage.setItem('gamePreguntasTime', '');
      localStorage.setItem('gameDescifrar', '');
      localStorage.setItem('gameDescifrarScore', '');
      localStorage.setItem('gameDescifrarTime', '');

      localStorage.setItem('gameCarrera', '');
      localStorage.setItem('gameCarreraScore', '');
      localStorage.setItem('gameCarreraLife', '');

      localStorage.setItem('gameConcentrese', '');
      localStorage.setItem('gameConcentreseScore', '');
      localStorage.setItem('gameConcentreseTime', '');

      localStorage.setItem('gameBubble', '');
      localStorage.setItem('gameBubbleScore', '');
      localStorage.setItem('gameBubbleShots', '');

      setState({
        ...state,
        screen: '',
      })
      
      audio.play('button')
      navigate('/welcome', { replace: true });
    })
  }

  /**
   * HANDLE INIT
   * Inicia el juego con la musica
   */

  const handleInit = () => {
    const overlayInit = document.querySelector('.Home-overlay-init');
    overlayInit.style.display = 'none';
    audio.restart('music');
  }

  return (
    <React.Fragment>
      <div className='Home'>
        <div className='Home-overlay-init'>
          <Overlay>
            <button className='Home-init-btn' onClick={() => handleInit()}>Iniciar</button>
          </Overlay>
        </div>

        <State />
        <div className='Home-background' />
        <div className='Home-logo-familia-pharmaton' />
        <div className='Home-familia-pharmaton' />
        <div className='Home-franja' />
        <img className='Home-registrarse-img' src={registrarse} alt="Registrarse" onClick={() => { handleState('registro'); audio.play('button') }} />
        
        {state.screen === 'registro' && 
          <Overlay>
            <div className="Home-registro zoomIn">
              <img className='Home-registro-img' src={registro} alt="Registro" />
              <form onSubmit={handleSubmit(onSubmit)}>
                <input className='Home-farmacia-input' {...register("pharmacy")} type="text" />
                <input className='Home-nombre-input' {...register("fullname", { required: true })} type="text" />
                { errors.fullname && <div className='Home-nombre-error'>El nombre es obligatorio.</div> }
                <button className='Home-continuar-btn' onClick={() => handleSubmit()} />
              </form>
              <button className='Home-registro-close-btn' onClick={() => { handleState(''); audio.play('modalOut') }} />
            </div>
            <p className='Home-registro-error'></p>
          </Overlay>
        }
      </div>
    </React.Fragment>
  );
}

export default Home;