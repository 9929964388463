import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import Reloj from '../components/Reloj';
import useAudio from '../hooks/useAudio';

import seccion1_img from '../assets/images/level-1/cuadro-pregunta-1.png';
import seccion2_img from '../assets/images/level-1/cuadro-pregunta-2.png';
import seccion3_img from '../assets/images/level-1/cuadro-pregunta-3.png';
import seccionFin_img from '../assets/images/level-1/cuadro-pregunta-fin.png';
import seccion4_img from '../assets/images/level-1/cuadro-descifremos.png';

import pregunta1_img from '../assets/images/level-1/cuadro-pregunta-1-modal.png';
import pregunta2_img from '../assets/images/level-1/cuadro-pregunta-2-modal.png';
import pregunta3_img from '../assets/images/level-1/cuadro-pregunta-3-modal.png';

import alerta_timeout_img from '../assets/images/level-1/cuadro-ups-tiempo.png';
import alerta1_ok_img from '../assets/images/level-1/cuadro-excelente-acertaste.png';
import alerta1_error_img from '../assets/images/level-1/cuadro-ups-fallaste.png';
import alerta2_ok_img from '../assets/images/level-1/cuadro-correcto.png';
import alerta2_error_img from '../assets/images/level-1/cuadro-respuesta-incorrecta.png';
import alerta3_ok_img from '../assets/images/level-1/cuadro-muy-bien.png';
import alerta3_error_img from '../assets/images/level-1/cuadro-fallaste.png';
import alerta4_ok_img from '../assets/images/level-1/cuadro-excelente-recuerda.png';
import alerta4_error_img from '../assets/images/level-1/cuadro-ups-intentalo-nuevo.png';
import alerta_referencias_img from '../assets/images/level-1/cuadro-referencias.png';
import menu_btn from '../assets/images/common/boton-volver-menu.png';
import referencias_btn from '../assets/images/common/boton-referencias.png';
import calificanos_btn from '../assets/images/common/boton-calificanos.png';

const Level1 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('level1');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

  const handleState = (screen, clock = 'off') => {
    setState({
      ...state,
      screen: screen,
      clock: clock,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    console.log('Save score!');

    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: Number(localStorage.getItem('gamePreguntasScore')) + Number(localStorage.getItem('gameDescifrarScore')),
      time: Number(localStorage.getItem('gamePreguntasTime')) + Number(localStorage.getItem('gameDescifrarTime')),
    }

    const rankingLevel1 = localStorage.getItem('rankingLevel1');

    if (!rankingLevel1) {
      console.log('no existe ranking');
      localStorage.setItem('rankingLevel1', JSON.stringify([score]));
    } else {
      console.log('ya existe ranking');
      const ranking = JSON.parse(localStorage.getItem('rankingLevel1'));
      ranking.push(score);

      localStorage.setItem('rankingLevel1', JSON.stringify(ranking));
    }

    // let formData = new FormData();
    // formData.append('name', localStorage.getItem('fullname'));
    // formData.append('pharmacy', localStorage.getItem('pharmacy'));
    // formData.append('gamePreguntasScore', localStorage.getItem('gamePreguntasScore'));
    // formData.append('gamePreguntasTime', localStorage.getItem('gamePreguntasTime'));
    // formData.append('gameDescifrarScore', localStorage.getItem('gameDescifrarScore'));
    // formData.append('gameDescifrarTime', localStorage.getItem('gameDescifrarTime'));
    
    // axios.post('http://127.0.0.1:8000/api/score', formData, {
    //   headers: {
    //     "Authorization": `Bearer ${ JSON.parse(localStorage.getItem('token'))} `
    //   }
    // })
    // .then((res) => {
    //   if (res.data.transaction.status) {
    //     console.log('puntaje guardado:');
    //   }
    // }).catch((err) => {
    //   console.log('error al guardar puntaje:', err);
    // })
  }

  /**
   * VALIDAR TEXTO
   * Revisa si escribio todas las palabras correctamente
   */
  
  const validateText = () => {
    const input1 = document.querySelector('.Level1-palabra-1');
    const input2 = document.querySelector('.Level1-palabra-2');
    const input3 = document.querySelector('.Level1-palabra-3');
    const input4 = document.querySelector('.Level1-palabra-4');

    console.log(input1.value.toLowerCase());

    if (
      input1.value.toLowerCase() === 'sano crecimiento' &&
      input2.value.toLowerCase() === 'estimula el apetito' &&
      (input3.value.toLowerCase() === 'adecuada nutrición' || input3.value.toLowerCase() === 'adecuada nutricion') &&
      input4.value.toLowerCase() === 'fortalece defensas'
    ) {
      handleState('seccion4-ok', 'off');
      audio.play('nice');
      audio.play('youWin');
      audio.stop('tickTock');
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('seccion4-ok');
  // }, []);

  return (
    <div className='Level1'>
      <State />
      <div className='Level1-background fadeIn' />
      <div className='Level1-logo-pharmaton' />
      <div className='Level1-franja' />
      <div className='Level1-nivel bounceIn' />
      <button className='button right top' onClick={() => { 
        handleNavigate('welcome'); 
        audio.play('modalOut') 
      }} >MENU</button>

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gamePreguntas', 'ready') }
          { localStorage.setItem('gamePreguntasScore', 0) }
          { localStorage.setItem('gamePreguntasTime', 0) }
          { localStorage.setItem('gameDescifrar', 'ready') }
          { localStorage.setItem('gameDescifrarScore', 0) }
          { localStorage.setItem('gameDescifrarTime', 0) }

          <div className='Level1-presentacion zoomIn' onClick={() => { 
            handleState('instrucciones');
            audio.play('button');
          }} />
          <div className='Level1-nina-1' />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <div className='Level1-mensaje zoomIn' onClick={() => { 
            handleState('seccion1'); 
            audio.play('button');
          }} />
          <div className='Level1-nina-2' />
        </React.Fragment>
      }

      {/* SECCION 1 ------ */}

      {state.screen === 'seccion1' &&
        <Overlay>
          <div className='Level1-seccion slideUp'>
            <img className='Level1-seccion-img' src={seccion1_img} alt="Seccion" />
            <button className='Level1-pregunta-btn Level1-pregunta1-btn' onClick={() => { 
              handleState('pregunta1', 'on'); 
              audio.play('button');
              audio.play('tickTock');
            }} />
          </div>
          <button className='button right bottom over' onClick={() => { 
            handleState('');
            audio.play('modalOut');
          }}>SALIR</button>
        </Overlay>
      }

      {/* Pregunta 1 */}

      {state.screen === 'pregunta1' && state.clock === 'on' &&
        <Overlay>
          <div className='Level1-pregunta zoomIn'>
            <img className='Level1-pregunta1-img' src={pregunta1_img} alt="Pregunta" />
            <button className='Level1-pregunta1-respuesta1-btn' onClick={() => { 
              handleState('pregunta1-error', 'off'); 
              audio.play('wrong');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta1-respuesta2-btn' onClick={() => { 
              handleState('pregunta1-error', 'off'); 
              audio.play('wrong');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta1-respuesta3-btn' onClick={() => { 
              handleState('pregunta1-ok', 'off');
              audio.play('nice');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta-close-btn' onClick={() => { 
              handleState('seccion1', 'off');
              audio.play('modalOut');
              audio.stop('tickTock');
            }} />
          </div>
          
          <Reloj timeOut="30" timeOutScreen={'pregunta1-timeout'} game="Preguntas" />
        </Overlay>
      }

      {/* Pregunta 1 - Callbacks */}

      {state.screen === 'pregunta1-timeout' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta_timeout_img} alt="Alerta" onClick={() => {
            handleState('pregunta1', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
        </Overlay>
      }

      {state.screen === 'pregunta1-error' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta1_error_img} alt="Alerta" onClick={() => {
            handleState('pregunta1', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
        </Overlay>
      }

      {state.screen === 'pregunta1-ok' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta1_ok_img} alt="Alerta" onClick={() => {
            handleState('seccion2', 'off');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* SECCION 2 ------ */}

      {state.screen === 'seccion2' &&
        <Overlay>
          <div className='Level1-seccion zoomIn'>
            <img className='Level1-seccion-img' src={seccion2_img} alt="Seccion" />
            <button className='Level1-pregunta-btn Level1-pregunta2-btn' onClick={() => { 
              handleState('pregunta2', 'on');
              audio.play('button');
              audio.play('tickTock');
            }} />
          </div>
          <button className='button right bottom over' onClick={() => {
            handleState('');
            audio.play('modalOut');
          }}>SALIR</button>
        </Overlay>
      }

      {/* Pregunta 2 */}

      {state.screen === 'pregunta2' && state.clock === 'on' &&
        <Overlay>
          <div className='Level1-pregunta zoomIn'>
            <img className='Level1-pregunta2-img' src={pregunta2_img} alt="Pregunta" />
            <button className='Level1-pregunta2-respuesta1-btn' onClick={() => {
              handleState('pregunta2-error', 'off');
              audio.play('wrong');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta2-respuesta2-btn' onClick={() => {
              handleState('pregunta2-ok', 'off');
              audio.play('nice');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta2-respuesta3-btn' onClick={() => {
              handleState('pregunta2-error', 'off');
              audio.play('wrong');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta-close-btn' onClick={() => {
              handleState('seccion2', 'off');
              audio.play('modalOut');
              audio.stop('tickTock');
            }} />
          </div>
          
          <Reloj timeOut="30" timeOutScreen={'pregunta2-timeout'} game="Preguntas" />
        </Overlay>
      }

      {/* Pregunta 2 - Callbacks */}

      {state.screen === 'pregunta2-timeout' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta_timeout_img} alt="Alerta" onClick={() => {
            handleState('pregunta2', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
        </Overlay>
      }

      {state.screen === 'pregunta2-error' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta2_error_img} alt="Alerta" onClick={() => {
            handleState('pregunta2', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
        </Overlay>
      }

      {state.screen === 'pregunta2-ok' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta2_ok_img} alt="Alerta" onClick={() => {
            handleState('seccion3', 'off');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* SECCION 3 ------ */}

      {state.screen === 'seccion3' &&
        <Overlay>
          <div className='Level1-seccion zoomIn'>
            <img className='Level1-seccion-img' src={seccion3_img} alt="Seccion" />
            <button className='Level1-pregunta-btn Level1-pregunta3-btn' onClick={() => {
              handleState('pregunta3', 'on');
              audio.play('button');
              audio.play('tickTock');
            }} />
          </div>
          <button className='button right bottom over' onClick={() => {
            handleState('');
            audio.play('modalOut');
          }}>SALIR</button>
        </Overlay>
      }

      {/* Pregunta 3 */}

      {state.screen === 'pregunta3' && state.clock === 'on' &&
        <Overlay>
          <div className='Level1-pregunta zoomIn'>
            <img className='Level1-pregunta3-img' src={pregunta3_img} alt="Pregunta" />
            <button className='Level1-pregunta3-respuesta1-btn' onClick={() => {
              handleState('pregunta3-ok', 'off');
              audio.play('nice');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta3-respuesta2-btn' onClick={() => {
              handleState('pregunta3-error', 'off');
              audio.play('wrong');
              audio.stop('tickTock');
            }} />
            <button className='Level1-pregunta-close-btn' onClick={() => {
              handleState('seccion3', 'off');
              audio.play('modalOut');
              audio.stop('tickTock');
            }} />
          </div>
          
          <Reloj timeOut="30" timeOutScreen={'pregunta3-timeout'} game="Preguntas" />
        </Overlay>
      }

      {/* Pregunta 3 - Callbacks */}

      {state.screen === 'pregunta3-timeout zoomOut' &&
        <Overlay>
          <img className='Level1-alerta-img' src={alerta_timeout_img} alt="Alerta" onClick={() => {
            handleState('pregunta3', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
        </Overlay>
      }

      {state.screen === 'pregunta3-error' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta3_error_img} alt="Alerta" onClick={() => {
            handleState('pregunta3', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
        </Overlay>
      }

      {state.screen === 'pregunta3-ok' &&
        <Overlay>
          { localStorage.setItem('gamePreguntas', 'finish') }
          { localStorage.setItem('gamePreguntasScore', 3) }

          <img className='Level1-alerta-img zoomOut' src={alerta3_ok_img} alt="Alerta" onClick={() => {
            handleState('seccionFin', 'off');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* SECCION FIN ------ */}

      {state.screen === 'seccionFin' &&
        <Overlay>
          <div className='Level1-seccion zoomIn'>
            <img className='Level1-seccion-img' src={seccionFin_img} alt="Seccion" />
            <button className='Level1-pregunta-btn Level1-fin-btn' onClick={() => {
              handleState('seccion4');
              audio.play('button');
              audio.play('tickTock');
            }}/>
          </div>
          <button className='button right bottom over' onClick={() => {
            handleState('');
            audio.play('modalOut');
          }}>SALIR</button>
        </Overlay>
      }

      {/* SECCION 4 ------ */}

      {state.screen === 'seccion4' &&
        <Overlay>
          <div className='Level1-seccion zoomIn'>
            <img className='Level1-seccion-img' src={seccion4_img} alt="Seccion" />
            <label className='Level1-pregunta-btn Level1-palabra-1-btn' htmlFor="Level1-palabra-1" />
            <label className='Level1-pregunta-btn Level1-palabra-2-btn' htmlFor="Level1-palabra-2" />
            <label className='Level1-pregunta-btn Level1-palabra-3-btn' htmlFor="Level1-palabra-3" />
            <label className='Level1-pregunta-btn Level1-palabra-4-btn' htmlFor="Level1-palabra-4" />
            <input type="text" className="Level1-palabra Level1-palabra-1" id='Level1-palabra-1' onChange={() => validateText()} />
            <input type="text" className="Level1-palabra Level1-palabra-2" id='Level1-palabra-2' onChange={() => validateText()} />
            <input type="text" className="Level1-palabra Level1-palabra-3" id='Level1-palabra-3' onChange={() => validateText()} />
            <input type="text" className="Level1-palabra Level1-palabra-4" id='Level1-palabra-4' onChange={() => validateText()} />
            <button className='Level1-pregunta-close-btn' onClick={() => {
              handleState('seccionFin', 'off');
              audio.play('modalOut');
              audio.stop('tickTock');
            }} />
          </div>

          <Reloj timeOut="40" timeOutScreen={'seccion4-timeout'} game="Descifrar" />
        </Overlay>
      }

      {/* Seccion 4 - Callbacks */}

      {state.screen === 'seccion4-timeout' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta_timeout_img} alt="Alerta" onClick={() => {
            handleState('seccion4', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
          <img className='Level1-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
            handleState('referencias-timeout');
            audio.play('button');
          }} />
        </Overlay>
      }

      {state.screen === 'seccion4-error' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta4_error_img} alt="Alerta" onClick={() => {
            handleState('seccion4', 'on');
            audio.play('modalOut');
            audio.play('tickTock');
          }} />
          <img className='Level1-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
            handleState('referencias-error');
            audio.play('button');
          }} />
        </Overlay>
      }

      {state.screen === 'seccion4-ok' &&
        <Overlay>
          { localStorage.setItem('gameDescifrar', 'finish') }
          { localStorage.setItem('gameDescifrarScore', 4) }

          { console.log('enviar y limpiar datos...') }
          { console.log('gamePreguntas:', localStorage.getItem('gamePreguntasScore')) }
          { console.log('gamePreguntasScore:', localStorage.getItem('gamePreguntasScore')) }
          { console.log('gamePreguntasTime:', localStorage.getItem('gamePreguntasTime')) }
          { console.log('gameDescifrar:', localStorage.getItem('gameDescifrar')) }
          { console.log('gameDescifrarScore:', localStorage.getItem('gameDescifrarScore')) }
          { console.log('gameDescifrarTime:', localStorage.getItem('gameDescifrarTime')) }

          { saveScore() }

          <img className='Level1-alerta-img zoomOut' src={alerta4_ok_img} alt="Alerta" onClick={() => {
            handleNavigate('level2');
            audio.play('button');
          }} />
          <img className='Level1-referencias-btn' src={referencias_btn} alt="Alerta" onClick={() => {
            handleState('referencias-ok');
            audio.play('button');
          }} />
          <img className='Level1-calificanos-btn' src={calificanos_btn} alt="Alerta" onClick={() => {
            handleNavigate('finish');
            audio.play('button');
          }} />
          <img className='Level1-menu-btn' src={menu_btn} alt="Alerta" onClick={() => {
            handleNavigate('welcome');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'referencias-timeout' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('seccion4-timeout');
             audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'referencias-error' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('seccion4-error');
             audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'referencias-ok' &&
        <Overlay>
          <img className='Level1-alerta-img zoomOut' src={alerta_referencias_img} alt="Alerta" onClick={() => {
            handleState('seccion4-ok');
             audio.play('modalOut');
          }} />
        </Overlay>
      }
    </div>
  );
}

export default Level1;